<template>
  <PageTitleTop>
    <template v-slot:title>冻结资金</template>
    <PageWarnMessage>
      冻结资金是确保交易过程汇总对相关资产进行保全额措施
    </PageWarnMessage>
    <HeaderAreaVue :isFrozen="true"></HeaderAreaVue>
    <PageSection>
      <template v-slot:title>冻结资金变更历史</template>
      <Row>
        <Col span="6">
          <DatePicker
            :model-value="searchData.time"
            format="yyyy-MM-dd"
            type="daterange"
            placement="bottom-end"
            placeholder="选择时间段"
            style="width: 200px"
            @on-change="dateChange"
          ></DatePicker>
        </Col>
        <Col span="4">
          <Select
            v-model="searchData.otherTime"
            style="width: 200px"
            clearable
            @on-change="timeRangeChange"
          >
            <Option :value="1">最近一周</Option>
            <Option :value="2">最近一个月</Option>
            <Option :value="3">最近三个月</Option>
            <Option :value="4">最近半年</Option>
            <Option :value="5">最近一年</Option>
          </Select>
        </Col>
      </Row>
      <Table
        :columns="columns"
        :data="dataList"
        :fixed-shadow="'auto'"
        class="table-box"
      >
        <template #orderDetail="{ row }">
          <div
            class="order-detail-td"
						@click="toBankBackOrder(row)"
          >
            <div>
              <!-- {{ '订单【' + row.serialNo + '】' + ' 交易冻结' }} -->
              {{ row.description }}
            </div>
            <div class="secondary-info">
              {{ row.transDate }} {{ row.transDateTime }}
            </div>
          </div>
        </template>
        <template #amount="{ row }">
          <div class="amount">
            {{ row.flag === 0 ? `-${row.amount}` : `+${row.amount}` }}
            <!-- {{ '+' + row.amount }} -->
          </div>
        </template>
      </Table>
      <Row justify="end" class="table-page">
        <Page
          v-model="pageInfo.pageIndex"
          :total="pageInfo.total"
          :page-size="pageInfo.pageSize"
          @on-change="pageChange"
        />
      </Row>
    </PageSection>
  </PageTitleTop>
</template>

<script>
import { initFrozenMoneyListHeader } from '../config'
import HeaderAreaVue from '../components/HeaderArea'
import moment from 'moment'
export default {
  name: 'FrozenMoney',
  data () {
    return {
      searchData: {
        time: [],
        otherTime: '',
      },
      pageInfo: {
        pageIndex: 1,
        total: 0,
        pageSize: 10,
      },
      dataList: [],
    }
  },
  setup () {
    const columns = initFrozenMoneyListHeader()
    return {
      columns,
    }
  },
  beforeRouteUpdate () {
    const type = this.$store.state.member.memberCenter.status
    if (type === 7) {
      // 已开户
      this.$router.push('/user/account/balance')
    } else if (type === 4 || type === 5 || type === 6) {
      // 待开户 | 开户失败
      this.$router.push('/user/account/balance/open-account').then(() => {
        this.showPage = true
      })
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    timeRangeChange (val) {
      if (!val) {
        return
      }
      this.searchData.time = []
      this.searchData.time[1] = moment().format('YYYY-MM-DD')
      switch (val) {
        case 1:
          this.searchData.time[0] = moment()
            .subtract(7, 'days')
            .format('YYYY-MM-DD')
          break
        case 2:
          this.searchData.time[0] = moment()
            .subtract(1, 'month')
            .format('YYYY-MM-DD')
          break
        case 3:
          this.searchData.time[0] = moment()
            .subtract(3, 'month')
            .format('YYYY-MM-DD')
          break
        case 4:
          this.searchData.time[0] = moment()
            .subtract(6, 'month')
            .format('YYYY-MM-DD')
          break
        case 5:
          this.searchData.time[0] = moment()
            .subtract(1, 'year')
            .format('YYYY-MM-DD')
          break
      }
      this.getDetail()
    },
    getDetail () {
      const time = this.searchData.time.length ? this.searchData.time : ['', '']
      const params = {
        page: this.pageInfo.pageIndex,
        rows: this.pageInfo.pageSize,
        startDate: time[0].replace(/-/g, ''),
        endDate: time[1].replace(/-/g, ''),
        serialNo: '',
        orderNo: '',
        transType: 1401, // 解冻/冻结
      }
      this.$store
        .dispatch('accountMoney/GetDetail', params)
        .then((res) => {
          if (res.returnCode === '1') {
            this.dataList = res.result.rows
            this.pageInfo.total = res.result.records
          }
        })
        .catch(() => {})
    },
		toBankBackOrder (data) {
			this.showSpin()
      // console.log(data.transDate.replace(/-/g, ''))
      const params = {
        serialNo: data.serialNo,
        transDate: data.transDate.replace(/-/g, ''),
      }
      this.$store
        .dispatch('accountMoney/BankBackOrder', params)
        .then((res) => {
					this.$Spin.hide()
          if (res.returnCode === '1') {
						window.open(res.result.authUrl, '_self')
          }
        })
        .catch(() => {})
    },
    showSpin () {
      this.$Spin.show({
        render: (h) => {
          return h('div', [
            h(
              'div',
              {
                style: {
                  color: '#d12b23',
                  fontSize: '22px',
                },
              },

              '正在加载银行数据。请等待...',
            ),
          ])
        },
      })
    },
    toDetail () {},
    pageChange () {
      this.getDetail()
    },
    dateChange (val) {
      this.searchData.otherTime = ''
      this.searchData.time = val
      this.getDetail()
    },
  },
  components: {
    HeaderAreaVue,
  },
}
</script>

<style lang="less" scoped>
.code-row-bg {
  padding: 20px 0;
}
.header-area {
  border-bottom: 2px solid #f4f5f7;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.table-box {
  margin-top: 20px;
}

.amount {
  font-size: 18px;
}
</style>
